
























import { Component, Prop } from "vue-property-decorator";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { mixins } from "vue-class-component";
import { Gender } from "@/enum/UserTitle.enum";
import { UserDegree } from "@/enum/UserDegree";
import { AUTH_STORE_NAME, AuthStoreActions } from "@/store/auth.store";
import { namespace } from "vuex-class";
import IconTooltipComponent from "@/components/IconTooltip.component.vue";
import { UserType } from "@/enum/UserType.enum";
import { UserRoles } from "@/enum/UserRoles.enum";
import { passwordCriteria } from "@/misc/PasswortCriterias";
import User from "@/models/User.model";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { APPLICATION_STORE_NAME } from "@/store/application.store";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({

})
export default class DeleteUserComponent extends mixins(
  ErrorMessageHandlerMixin
) {
  @Prop({ default: User.parseFromObject({}) })
  public user!: User;

  @AuthStore.Action(AuthStoreActions.DELETE_USER)
  private deleteUser!: (payload: { user: User }) => Promise< User | null>;

  private loading: boolean = false;

  private async onDelete() {
    try {
      this.loading = true;
      await this.deleteUser({ user: this.user });
      this.$notifySuccessSimplified(
        "GENERAL.NOTIFICATIONS.DELETED"
      );
      this.dismiss();
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.ERROR_GENERAL");
        }
      });
    } finally {
      this.loading = false;
    }
  }

  private dismiss() {
    this.$emit("closeDialog");
  }
}
